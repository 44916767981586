import React from 'react';

import { loadCSS } from '../../../../utils/loadCSS';
import '../../../../assets/styles/myvouchercodes.css';

loadCSS('myvouchercodes-site');

export const MyvouchercodesSiteWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <>{children}</>;
};
export default MyvouchercodesSiteWrapper;
